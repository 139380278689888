import { FC, useMemo } from 'react';
import { Progress } from 'antd';
import styles from './index.module.scss';
import { getCoveredCategories } from '../../../../lib/utils/end-user-helpers';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { parseJSON } from '../../../../lib/utils/helpers';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useIsEviTenant } from '../../../../lib/hooks/endUserHooks/useIsEviTenant';

const translation = 'endUser.shared';

type Props = {
  currentDemandArea: any;
  containerClassName?: string;
  applyNewDesign?: boolean;
};

export const ProgressBar: FC<Props> = ({ currentDemandArea, containerClassName, applyNewDesign }) => {
  const { isTablet } = useContainerQuery();
  const { t } = useTranslation();

  const isEviTenant = useIsEviTenant();

  const totalCategories = currentDemandArea?.categories.length;
  const coveredCategoriesAmount = getCoveredCategories(currentDemandArea?.categories).length;

  const progressPercent = (coveredCategoriesAmount / totalCategories) * 100;

  const demandAreaColor = parseJSON(currentDemandArea.color);

  const eviTenantStylesOverride = useMemo(() => {
    return isEviTenant &&
      (currentDemandArea?.position.number === 1 || currentDemandArea?.position.number === 3)
      ? styles.eviProgress
      : '';
  }, [currentDemandArea?.position.number, isEviTenant]);

  return (
    <div
      className={cn(
        styles.progressBarWrapper,
        containerClassName && containerClassName,
        applyNewDesign && styles.progressBarNew,
        eviTenantStylesOverride,
      )}
    >
      <EndUserTypography
        className={styles.title}
        component="Title"
        type="headlineFirst"
        style={{ marginBottom: isTablet ? '16px' : '32px' }}
      >
        {currentDemandArea.name}
      </EndUserTypography>
      <div className={styles.progressBar}>
        <Progress
          steps={totalCategories}
          percent={progressPercent}
          showInfo={false}
          strokeColor={demandAreaColor.value}
        />
      </div>
      <EndUserTypography
        className={styles.subline}
        type="headlineFifth"
        component="Text"
        style={{ color: demandAreaColor.value }}
      >
        {t(`${translation}.coveredCategories`, {
          coveredCategoriesAmount: coveredCategoriesAmount,
          totalCategories: totalCategories,
        })}
      </EndUserTypography>
    </div>
  );
};
