import { FC, useCallback, useMemo } from 'react';
import { ProgressBar } from '../ProgressBar';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import styles from './index.module.scss';
import cn from 'classnames';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { parseJSON } from '../../../../lib/utils/helpers';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { SmallDemandAreaHouse } from '../SmallDemandAreaHouse';
import { useIsEviTenant } from '../../../../lib/hooks/endUserHooks/useIsEviTenant';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';

type Props = {
  handleTabClick: (index: number) => void;
  activeTab?: number;
  currentDemandArea: any;
  demandAreas: DemandArea[];
  onClickCategory: (category: Category) => void;
};

const sortCategoriesByPriority = (categories: any[]) => {
  return categories.slice().sort((a, b) => a.priority.number - b.priority.number);
};

const windowSpecificDesktopSize = 1880;
const windowSpecificDesktopSize2 = 1403;

export const SquareShadowHouse: FC<Props> = ({
  handleTabClick,
  activeTab,
  currentDemandArea,
  demandAreas,
  onClickCategory,
}) => {
  const { isTablet, isLargerTablet, isDesktop } = useContainerQuery();
  const { windowWidth } = useWindowResize();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleTabClick(index);
    }
  };

  const handleKeyDownCategory = (event: React.KeyboardEvent<HTMLDivElement>, category: Category) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickCategory(category);
    }
  };

  const isEviTenant = useIsEviTenant();

  const getTranslatePercent = useCallback(() => {
    if (isTablet) {
      return '40%';
    } else if (windowWidth <= windowSpecificDesktopSize && windowWidth >= windowSpecificDesktopSize2) {
      return '20%';
    } else if (windowWidth < windowSpecificDesktopSize2) {
      return '25%';
    } else {
      return '15%';
    }
  }, [isTablet, windowWidth]);

  const bigSquareStyle = useMemo(() => {
    const isApplySpecificTranslate =
      isEviTenant && (currentDemandArea.position.number === 1 || currentDemandArea.position.number === 3);

    const eviTenantTabletTranslatePercent = isApplySpecificTranslate
      ? getTranslatePercent()
      : `-${(Number(activeTab) % 2) * 50}%`;

    return {
      transform: `translate(${eviTenantTabletTranslatePercent}, -${Math.floor(Number(activeTab) / 2) * 50}%)`,
    };
  }, [activeTab, currentDemandArea.position.number, getTranslatePercent, isEviTenant]);

  const demandAreaColor = useMemo(() => {
    return parseJSON(currentDemandArea.color);
  }, [currentDemandArea]);

  return (
    <div className={styles.squareSectionContainer}>
      <div className={styles.squareContainer} style={{ backgroundColor: demandAreaColor.value }}>
        <SmallDemandAreaHouse
          onAreaClick={handleTabClick}
          onClickCategory={onClickCategory}
          demandAreaColor={demandAreaColor.value}
          demandAreas={demandAreas}
          currentDemandArea={currentDemandArea}
        />
        <div className={styles.сontainer} style={bigSquareStyle}>
          <div className={styles.pentagon} style={{ backgroundColor: demandAreaColor.value }}>
            {demandAreas.slice(0, 2).map((area: any, outerIndex: any) => {
              const areaColor = parseJSON(area?.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);
              return (
                <div
                  tabIndex={0}
                  role="button"
                  className={cn(
                    styles.pentagonSector,
                    sortedCategories.length > 3 && styles.pentagonFourSector,
                  )}
                  onClick={() => handleTabClick(outerIndex)}
                  onKeyDown={(event) => handleKeyDown(event, outerIndex)}
                  key={outerIndex}
                  style={{
                    background:
                      activeTab === outerIndex
                        ? `linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), ${areaColor.value}`
                        : `linear-gradient(0deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.85) 100%)`,
                  }}
                >
                  <div>
                    {sortedCategories.map((category: any, innerIndex: any) => {
                      const hasUserProducts = checkBoughtProductsInCategory(category);

                      return (
                        <div
                          tabIndex={0}
                          role="button"
                          style={{
                            boxShadow:
                              activeTab !== outerIndex
                                ? 'none'
                                : hasUserProducts
                                  ? '0px 5px 30px rgba(0, 0, 0, 0.05), 1px 4px 5px rgba(0, 0, 0, 0.30)'
                                  : '2px -2px 4px 0px rgba(0, 0, 0, 0.30) inset',
                            background:
                              activeTab !== outerIndex
                                ? `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), ${demandAreaColor.value}`
                                : hasUserProducts
                                  ? areaColor.value
                                  : 'rgba(255, 255, 255, 0.85)',
                            cursor: activeTab === outerIndex ? 'pointer' : 'default',
                          }}
                          onClick={(event) => {
                            event.stopPropagation();

                            onClickCategory(category);
                          }}
                          onKeyDown={(event) => handleKeyDownCategory(event, category)}
                          key={innerIndex}
                          className={styles.trapezoidCard}
                        >
                          {activeTab === outerIndex ? (
                            <>
                              <IconComponent
                                className={styles.icon}
                                iconData={category?.icon}
                                color={hasUserProducts ? '#fff' : areaColor.value}
                              />
                              <EndUserTypography
                                type="subText"
                                component="Text"
                                className={styles.title}
                                style={{
                                  color: hasUserProducts ? '#fff' : areaColor.value,
                                  textAlign: 'center',
                                  minHeight: '40px',
                                }}
                              >
                                {category.name}
                              </EndUserTypography>
                            </>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.rectangle} style={{ backgroundColor: demandAreaColor.value }}>
            {demandAreas.slice(2).map((area: any, outerIndex: any) => {
              const areaColor = parseJSON(area?.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              return (
                <div
                  tabIndex={0}
                  role="button"
                  className={styles.rectangleSector}
                  onClick={() => handleTabClick(outerIndex + 2)}
                  onKeyDown={(event) => handleKeyDown(event, outerIndex + 2)}
                  key={outerIndex}
                  style={{
                    background:
                      activeTab === outerIndex + 2
                        ? `linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), ${areaColor.value}`
                        : `linear-gradient(0deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.85) 100%)`,
                  }}
                >
                  <div className={styles.cardsWrapper}>
                    {sortedCategories.map((category: any, innerIndex: any) => {
                      const hasUserProducts = checkBoughtProductsInCategory(category);

                      return (
                        <div
                          tabIndex={0}
                          role="button"
                          className={styles.card}
                          onClick={(event) => {
                            event.stopPropagation();

                            onClickCategory(category);
                          }}
                          onKeyDown={(event) => handleKeyDownCategory(event, category)}
                          key={innerIndex}
                          style={{
                            boxShadow:
                              activeTab !== outerIndex + 2
                                ? 'none'
                                : hasUserProducts
                                  ? '0px 5px 30px rgba(0, 0, 0, 0.05), 1px 4px 5px rgba(0, 0, 0, 0.30)'
                                  : '2px -2px 4px 0px rgba(0, 0, 0, 0.30) inset',
                            background:
                              activeTab !== outerIndex + 2
                                ? `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), ${demandAreaColor.value}`
                                : hasUserProducts
                                  ? areaColor.value
                                  : 'rgba(255, 255, 255, 0.85)',
                            cursor: activeTab === outerIndex + 2 ? 'pointer' : 'default',
                          }}
                        >
                          {activeTab === outerIndex + 2 ? (
                            <div>
                              <IconComponent
                                className={styles.icon}
                                iconData={category?.icon}
                                color={hasUserProducts ? '#fff' : areaColor.value}
                              />

                              <EndUserTypography
                                className={styles.title}
                                type="subText"
                                component="Text"
                                style={{
                                  color: hasUserProducts ? '#fff' : areaColor.value,
                                  textAlign: 'center',
                                  minHeight: '40px',
                                }}
                              >
                                {category.name}
                              </EndUserTypography>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ProgressBar currentDemandArea={currentDemandArea} />
    </div>
  );
};
