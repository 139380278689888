import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { FontColorFormItem } from '../../../../features/form-items/FontColorFormItem';
import { useGetBrandKitColors } from '../../hooks/useGetBrandKitColors';
import { AppCheckbox } from '../../../../components/AppCheckox';

import styles from './index.module.scss';
import { AdditionalColorsT, ButtonColorsT } from '../../types';

const { Text } = Typography;
const texts = 'pages.designSettingsTab';

type Props = {
  colorSettings: ButtonColorsT;
  onChangeField: any;
  additionalColors: AdditionalColorsT;
};

export const ButtonColorCard: FC<Props> = ({ colorSettings, onChangeField, additionalColors }) => {
  const { t } = useTranslation();
  const colors = useGetBrandKitColors();

  const handleChangeField = (value: string, _fieldName?: string, field?: string) => {
    if (value) {
      onChangeField(field, value);
    }
  };

  const handleChangeCheckbox = (field: string, value: boolean) => {
    onChangeField(field, value);
  };

  return (
    <div className={styles.cardBodyWrapper}>
      <Text type="secondary" className={styles.text}>
        {t(`${texts}.color`)}
      </Text>
      <div className={styles.columns}>
        <div className={styles.column}>
          <AppCheckbox
            label={t(`${texts}.filled`)}
            onChange={(e) => {
              handleChangeCheckbox('isFilled', e.target.checked);
            }}
            checked={colorSettings.isFilled}
            style={{ marginBottom: '16px' }}
          />
          <FontColorFormItem
            value={colorSettings.filledColor}
            onChange={handleChangeField}
            field="filledColor"
            colors={colors}
            additionalColors={additionalColors}
            className={styles.colorFormSection}
            formItemProps={{ className: styles.colorFormItem }}
            selectProps={{ disabled: !colorSettings.isFilled }}
          />
        </div>
        <div className={styles.column}>
          <AppCheckbox
            label={t(`${texts}.outlined`)}
            onChange={(e) => {
              handleChangeCheckbox('isOutlined', e.target.checked);
            }}
            checked={colorSettings.isOutlined}
            style={{ marginBottom: '16px' }}
          />
          <FontColorFormItem
            value={colorSettings.outLinedColor}
            onChange={handleChangeField}
            field="outLinedColor"
            colors={colors}
            additionalColors={additionalColors}
            className={styles.colorFormSection}
            formItemProps={{ className: styles.colorFormItem }}
            selectProps={{ disabled: !colorSettings.isOutlined }}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.row}>
          <AppCheckbox
            label={t(`${texts}.activeColor`)}
            onChange={(e) => {
              handleChangeCheckbox('isActiveEnabled', e.target.checked);
            }}
            checked={colorSettings.isActiveEnabled}
            style={{ marginBottom: '16px', marginRight: '16px' }}
          />
        </div>
      </div>
      <div className={styles.columns}>
        <div className={styles.column}>
          <Text type="secondary" className={styles.text}>
            {t(`${texts}.backgroundColor`)}
          </Text>
          <FontColorFormItem
            value={colorSettings.activeBackgroundColor}
            onChange={handleChangeField}
            field="activeBackgroundColor"
            colors={colors}
            additionalColors={additionalColors}
            className={styles.colorFormSection}
            formItemProps={{ className: styles.colorFormItem }}
            selectProps={{ disabled: !colorSettings.isActiveEnabled }}
          />
        </div>
        <div className={styles.column}>
          <Text type="secondary" className={styles.text}>
            {t(`${texts}.textColor`)}
          </Text>
          <FontColorFormItem
            value={colorSettings.activeTextColor}
            onChange={handleChangeField}
            field="activeTextColor"
            colors={colors}
            additionalColors={additionalColors}
            className={styles.colorFormSection}
            formItemProps={{ className: styles.colorFormItem }}
            selectProps={{ disabled: !colorSettings.isActiveEnabled }}
          />
        </div>
      </div>
    </div>
  );
};
