import { Dispatch, FC } from 'react';
import { DefaultSecondaryButtonValues, DefaultPrimaryButtonValues } from '../../helpers/getDesignSettings';
import { Card, Radio, Typography, RadioChangeEvent, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormCardButton } from '../FormCardButton';
import { ButtonFontCard } from '../ButtonFontCard';
import { ButtonColorCard } from '../ButtonColorCard';
import { ReactComponent as IconRound } from '../../../../assets/icons/shape-round.svg';
import { ReactComponent as IconMidRound } from '../../../../assets/icons/shape-medium-round.svg';
import { ReactComponent as IconAngular } from '../../../../assets/icons/shape-angular.svg';

import styles from './index.module.scss';
import { ButtonShape, DesignSettingsDataT } from '../../types';

const designTexts = 'pages.designSettingsTab';
const { Text } = Typography;

type Props = {
  buttonType: 'primaryButton' | 'secondaryButton' | 'thirdButton';
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
};

export const ButtonCard: FC<Props> = ({ buttonType, designSettings, setDesignSettings }) => {
  const { t } = useTranslation();
  const buttonSettings = designSettings[buttonType];
  const { additionalColors } = designSettings;

  const handleChangeField = (fieldName: string, value: boolean | string) =>
    setDesignSettings({ ...designSettings, [buttonType]: { ...buttonSettings, [fieldName]: value } });

  const handleChangeFont = (fieldName: string, value: boolean | string) =>
    setDesignSettings({
      ...designSettings,
      [buttonType]: { ...buttonSettings, font: { ...buttonSettings.font, [fieldName]: value } },
    });

  const handleChangeColor = (fieldName: string, value: boolean | string) =>
    setDesignSettings({
      ...designSettings,
      [buttonType]: { ...buttonSettings, type: { ...buttonSettings.type, [fieldName]: value } },
    });

  const handleChangeShape = ({ target: { value } }: RadioChangeEvent) => {
    handleChangeField('shape', value);
  };

  const handleResetFields = () => {
    if (buttonType === 'primaryButton') {
      setDesignSettings({ ...designSettings, [buttonType]: DefaultPrimaryButtonValues });
    } else {
      setDesignSettings({ ...designSettings, [buttonType]: DefaultSecondaryButtonValues });
    }
  };

  const options = [
    {
      label: (
        <>
          <IconRound /> {t(`${designTexts}.shape.round`)}
        </>
      ),
      value: ButtonShape.ROUND,
    },
    {
      label: (
        <>
          <IconMidRound /> {t(`${designTexts}.shape.midRound`)}
        </>
      ),
      value: ButtonShape.MEDIUM_ROUND,
    },
    {
      label: (
        <>
          <IconAngular /> {t(`${designTexts}.shape.angular`)}
        </>
      ),
      value: ButtonShape.ANGULAR,
    },
  ];

  return (
    <Card
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Text className={styles.title}>{t(`${designTexts}.${buttonType}`)}</Text>
            <FormCardButton onClick={handleResetFields} />
          </div>
          <div>
            <Text type="secondary" className={styles.text}>
              {t(`${designTexts}.shape.label`)}
            </Text>

            <Radio.Group
              value={buttonSettings?.shape}
              className={styles.radioGroup}
              options={options}
              buttonStyle="solid"
              optionType="button"
              onChange={handleChangeShape}
            />
          </div>
        </>
      }
    >
      <ButtonFontCard
        fontSettings={buttonSettings?.font}
        additionalColors={additionalColors}
        onChangeField={handleChangeFont}
      />
      <Divider style={{ marginTop: -10 }} />
      <ButtonColorCard
        additionalColors={additionalColors}
        colorSettings={buttonSettings?.type}
        onChangeField={handleChangeColor}
      />
    </Card>
  );
};
