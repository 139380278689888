import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import { FontFamilyFormItem } from '../../../../features/form-items/FontFamilyFormItem';
import { FontWeightFormItem } from '../../../../features/form-items/FontWeightFormItem';
import { FontSizeFormItem } from '../../../../features/form-items/FontSizeFormItem';
import { FontColorFormItem } from '../../../../features/form-items/FontColorFormItem';
import { useGetFontsQuery } from '../../../../lib/store/api/brandKit';
import { useParams } from 'react-router-dom';
import { FontItemT } from '../../../../lib/types/colors&fonts';
import { useGetBrandKitColors } from '../../hooks/useGetBrandKitColors';

import styles from './index.module.scss';
import { AdditionalColorsT } from '../../types';

const { Text } = Typography;

type Props = {
  fontSettings: FontItemT;
  onChangeField: any;
  additionalColors: AdditionalColorsT;
};

export const ButtonFontCard: FC<Props> = ({ fontSettings, onChangeField, additionalColors }) => {
  const { t } = useTranslation();
  const { id: tenantId } = useParams();

  const { data: fontsFromServer } = useGetFontsQuery({ tenantId: tenantId as string }, { skip: !tenantId });
  const colors = useGetBrandKitColors();

  const handleChangeField = (value: string, _fieldName?: string, field?: string) => {
    if (value) {
      onChangeField(field, value);
    }
  };

  return (
    <div className={styles.cardBodyWrapper}>
      <Text type="secondary" className={styles.text}>
        {t('pages.designSettingsTab.font')}
      </Text>
      <Form.Item style={{ marginBottom: 0, fontWeight: 400 }}>
        <div className={styles.row}>
          <FontFamilyFormItem
            tenantId={tenantId as string}
            value={fontSettings.font}
            onChange={handleChangeField}
            field="font"
          />
          <FontWeightFormItem
            value={
              fontsFromServer?.map((fontObj) => fontObj.fontName).includes(fontSettings.font)
                ? undefined
                : fontSettings.fontWeight
            }
            onChange={onChangeField}
            field="fontWeight"
            disabled={fontsFromServer?.map((fontObj) => fontObj.fontName).includes(fontSettings.font)}
          />
          <FontSizeFormItem value={fontSettings.fontSize} onChange={handleChangeField} field="fontSize" />
          <FontColorFormItem
            value={fontSettings.colorVariant}
            onChange={handleChangeField}
            field="colorVariant"
            colors={colors}
            additionalColors={additionalColors}
          />
        </div>
      </Form.Item>
    </div>
  );
};
