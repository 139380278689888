import { FC, useMemo } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { ProductT } from '../../../../lib/types/product';
import { DemandArea } from '../../../../lib/types/endUser';
import { findDemandAreaColor } from '../../utils/helpers';
import styles from './index.module.scss';
import { useAppSelector } from '../../../../lib/store/hooks';
import { WishlistProductsListItem } from '../WishlistProductsListItem';
import { useTranslation } from 'react-i18next';
import { ScrollbarWrapper } from '../../../../app/components/ScrollbarWrapper';
import cn from 'classnames';

const translation = 'endUser.wishlist.productsList';

type Props = {
  wishlistData?: ProductT[];
  demandAreas?: DemandArea[];
  className?: string;
  buttonClassName?: string;
  productNameClassName?: string;
};

export const EndUserWishlistProductsList: FC<Props> = ({
  wishlistData,
  demandAreas,
  className,
  buttonClassName,
  productNameClassName,
}) => {
  const { userStatusLevel } = useAppSelector(({ endUser }) => endUser);
  const { currentPoints, wishedPoints, currentPointsInWishlist } = userStatusLevel;
  const estimatedPoints = wishedPoints + currentPointsInWishlist - currentPoints;
  const { t } = useTranslation();

  const scrollbarHeight = useMemo(() => {
    const rowHeight = 34;
    const blockHeight = 160;
    const gapHeight = 10;

    if (wishlistData) {
      if (wishlistData.length < 5 && wishlistData.length > 0) {
        return wishlistData.length * rowHeight - gapHeight;
      } else if (wishlistData?.length === 0) {
        return rowHeight * 1.5;
      } else {
        return blockHeight;
      }
    }
  }, [wishlistData]);

  const memoizedItemWrapperClassName = useMemo(() => {
    if (wishlistData) {
      return wishlistData.length > 5 ? styles.itemWrapperWithScrollbar : styles.itemWrapperWithoutScrollbar;
    }
  }, [wishlistData]);

  const memoizedProductCardClassName = useMemo(() => {
    if (wishlistData) {
      return cn(styles.productCard, wishlistData.length === 0 && styles.productCardWithoutContent);
    }
  }, [wishlistData]);

  return (
    <div className={cn(className, styles.container)}>
      <EndUserTypography type="headlineFourth" component="Title" className={styles.headerText} level={4}>
        {t(`${translation}.title`)} (+{wishlistData?.length || 0})
      </EndUserTypography>

      <div className={memoizedProductCardClassName}>
        <div className={styles.productCardHeader}>
          <EndUserTypography type="headlineEight" component="Text">
            {t(`${translation}.points`)}
          </EndUserTypography>
          {wishlistData?.length ? (
            <div className={styles.progressBarWrapper}>
              <EndUserTypography
                type="headlineSixth"
                component="Text"
                style={{ color: 'var(--primary-color)' }}
              >
                {estimatedPoints >= 0 ? '+ ' : ''}
                {estimatedPoints}★
              </EndUserTypography>
              <div className={styles.progressBar} />
            </div>
          ) : null}
        </div>
        <ul className={styles.productCardList}>
          <ScrollbarWrapper
            styles={{ width: 'inherit', maxHeight: '160px', height: scrollbarHeight }}
            scrollbarContentClassName={styles.scrollBarContent}
            trackYClassName={styles.trackY}
            trackYId="wishlistYScroll"
          >
            {wishlistData && wishlistData.length > 0 ? (
              wishlistData?.map((product, index: number) => {
                const areaColor = findDemandAreaColor(product.category?.id, demandAreas);

                return (
                  <WishlistProductsListItem
                    buttonClassName={buttonClassName}
                    productNameClassName={productNameClassName}
                    wrapperClassName={memoizedItemWrapperClassName}
                    key={index}
                    product={product}
                    areaColor={areaColor}
                  />
                );
              })
            ) : (
              <EndUserTypography
                type="headlineSeventh"
                component="Text"
                level={3}
                className={styles.emptyListText}
              >
                {t(`${translation}.emptyList`)}
              </EndUserTypography>
            )}
          </ScrollbarWrapper>
        </ul>
      </div>
    </div>
  );
};
